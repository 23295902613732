import '../banners.less';
import './index.less';

import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Fade from 'react-reveal/Fade';

import NewsletterForm from '../../../forms/newsletterForm';
import Title from '../../BannerShared/Title';

const KeepInTouch = () => {
  const { t } = useTranslation();

  return (
    <div className="banner-keep-in-touch">
      <div className="container">
        <section>
          <Row type="flex" align="middle">
            <Col xs={24} sm={24} md={24} lg={8}>
              <Fade>
                <Title title={t('home_keepintouch_title')} />
              </Fade>
            </Col>
            <Col xs={24} sm={24} md={24} lg={16}>
              <Fade>
                <NewsletterForm />
              </Fade>
            </Col>
          </Row>
        </section>
      </div>
    </div>
  );
};

KeepInTouch.propTypes = {
  t: PropTypes.func,
};

export default KeepInTouch;
