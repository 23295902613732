import './geosuggest.less';
import './index.less';

import { Alert, Button, Col, Form, Input, message, Row } from 'antd';
import { withFormik } from 'formik';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import * as Yup from 'yup';

import axios from '../../api/axios';

class NewsletterForm extends Component {
  state = {
    selectedLocation: null,
  };

  render() {
    const {
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      setFieldValue,
      setTouched,
      status,
      dirty,
      isValid,
      t,
    } = this.props;

    return (
      <Form onFinish={handleSubmit} className="form keepintouch-form">
        <Row gutter={{ xs: 6, sm: 16, md: 16, lg: 16 }}>
          <Col xs={16} sm={16} md={16} lg={10}>
            <Form.Item
              className="app-form-item"
              validateStatus={touched.email && errors.email ? 'error' : ''}
              help={touched.email && errors.email ? t(`${errors.email}`) : ''}
            >
              <Input
                type="email"
                size="large"
                placeholder={`${t(`keepintouch_emailaddress`).toLowerCase()}`}
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Item>
          </Col>

          <Col xs={8} sm={8} md={8} lg={4}>
            <Form.Item>
              <Button
                type="text"
                htmlType="submit"
                size="large"
                loading={status === 'loading'}
                disabled={!(isValid && dirty)}
              >
                {t(`Submit`)}
              </Button>
            </Form.Item>
          </Col>
        </Row>

        {errors.name && touched.name && <div id="feedback">{errors.name}</div>}

        {status === 'fail' ? <Alert type="error" message={t(`locations_jointhepresaleslist_errormsg`)} /> : null}
      </Form>
    );
  }
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .typeError(`validation_error_email_invalid_format`)
    .email('validation_error_email_invalid_format')
    .required('validation_error_email_required'),
});

const WrappedForm = withFormik({
  validationSchema,
  mapPropsToValues: () => ({
    email: null,
  }),
  handleSubmit: (values, { props, resetForm, setStatus }) => {
    const { t } = props;
    setStatus('loading');

    const formObj = {
      Email: values.email,
      City: '',
    };

    axios
      .post('/api/v1/public/keepintouch', formObj)
      .then((res) => {
        message.success(t(`locations_jointhepresaleslist_successmsg`));
        resetForm();
      })
      .catch((err) => {
        console.log('err', err);
        resetForm();
        setStatus('fail');
      });
  },
  displayName: 'Form',
})(NewsletterForm);

export default withTranslation()(WrappedForm);
